<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section">
      <div class="container">
        <div class="company-head size">
          <h2 v-html="$t('airline.title1')"></h2>
        </div>

        <div class="company-cards">
          <div class="card">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 56">
              <use href="@/assets/img/about-company/card1.svg#beginning"></use>
            </svg>
            <p v-html="$t('airline.tile1')"></p>
          </div>
          <div class="card">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 56">
              <use href="@/assets/img/about-company/card2.svg#leader"></use>
            </svg>
            <p v-html="$t('airline.tile2')"></p>
          </div>
          <div class="card">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 56">
              <use href="@/assets/img/about-company/card3.svg#save"></use>
            </svg>
            <p v-html="$t('airline.tile3')"></p>
          </div>
        </div>

        <div class="company-head size">
          <h2 v-html="$t('airline.title2')"></h2>
        </div>

        <div class="company-co">
          <div class="company-co__comands">
            <picture>
              <source media="(max-width: 1023px)" srcset="@/assets/img/about-company/672х426/3.jpg">
              <img src="@/assets/img/about-company/1_279х354.jpg" alt="">
            </picture>

          </div>
          <div class="company-co__plane">
            <picture>
              <source media="(max-width: 1439px)" srcset="@/assets/img/about-company/2_881х353.jpg">
              <img src="@/assets/img/about-company/2_1041х354.jpg" alt="">
            </picture>
          </div>
          <div class="company-co__exit">
            <picture>
              <source srcset="@/assets/img/about-company/672х426/4.jpg" media="(max-width: 1023px)" >
              <source srcset="@/assets/img/about-company/300х240/4.jpg" media="(max-width: 1279px)" >
              <source srcset="@/assets/img/about-company/672х610/3.jpg" media="(max-width: 1439px)" >
              <img src="@/assets/img/about-company/3_510х240.jpg" alt="">
            </picture>
          </div>
          <div class="company-co__stuardess">
            <picture>
              <source srcset="@/assets/img/about-company/672х426/2.jpg" media="(max-width: 1023px)" >
              <source media="(max-width: 767px)" srcset="@/assets/img/about-company/672х304/2.jpg">
              <source srcset="@/assets/img/about-company/300х240/2.jpg" media="(max-width: 1279px)" >
              <source srcset="@/assets/img/about-company/672х426/2.jpg" media="(max-width: 1439px)" >
              <img src="@/assets/img/about-company/4_506х240.jpg" alt="">
            </picture>
          </div>

          <div class="company-co__our-values company-head">
            <h2>
              {{ $t("airline.title3") }}
            </h2>
          </div>
          <div class="company-co__business co-cards">
            <ul>
              <h3>{{ $t("airline.tile4_title") }}</h3>
              <li>
                <span>{{ $t("airline.tile41") }}</span>
              </li>
              <li>
                <span>{{ $t("airline.tile42") }}</span>
              </li>
              <li>
                <span>{{ $t("airline.tile43") }}</span>
              </li>
            </ul>
          </div>
          <div class="company-co__on-you co-cards">
            <ul>
              <h3>{{ $t("airline.tile5_title") }}</h3>
              <li>
                <span>{{ $t("airline.tile51") }}</span>
              </li>
              <li>
                <span>{{ $t("airline.tile52") }}</span>
              </li>
              <li>
                <span>{{ $t("airline.tile53") }}</span>
              </li>
            </ul>
          </div>
          <div class="company-co__on-bort co-cards">
            <ul>
              <h3>
                {{ $t("airline.tile6_title") }}
              </h3>
              <li>
                <span>{{ $t("airline.tile61") }}</span>
              </li>
              <li>
                <span>{{ $t("airline.tile62") }}</span>
              </li>
              <li>
                <span>{{ $t("airline.tile63") }}</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="company-head size" v-html="$t('airline.title4')"></div>

        <div class="company-park">
          <div class="company-park__text" v-html="$t('airline.text')"></div>

          <div class="company-park__picture">
            <picture>
              <source
                srcset="@/assets/img/about-company/comand-park-s.jpg"
                media="(max-width: 414px)"
              />
              <source
                srcset="@/assets/img/about-company/comand-park-m.jpg"
                media="(max-width: 767px)"
              />
              <source
                srcset="@/assets/img/about-company/comand-park.jpg"
                media="(max-width: 1023px)"
              />
              <img src="@/assets/img/about-company/comand-park.jpg" alt="" />
            </picture>
          </div>

          <div class="company-park__info">
            <div
              class="company-park__info__data"
              v-html="$t('airline.tile7')"
            ></div>
            <div
              class="company-park__info__data"
              v-html="$t('airline.tile8')"
            ></div>
            <div
              class="company-park__info__data"
              v-html="$t('airline.tile9')"
            ></div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import cover from "@/mixins/cover";

export default {
  name: "Airline",
  title() {
    return this.$t("pages.flight");
  },
  mixins: [cover],
  data: () => {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>

<style scoped></style>
